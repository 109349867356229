import {UserInput} from '@wix/wixstores-client-core';
import {IRootStoreState} from '../../config/types';

export const getUserInput = (state: IRootStoreState): UserInput => {
  const {textInput, quantityInput, selectionItems} = state;
  const subscriptionPlan = state.subscriptionInput ? [{id: state.subscriptionInput}] : [];

  return {
    selection: selectionItems,
    quantity: [quantityInput],
    text: state.product.customTextFields.map((f, index) => textInput[index]),
    subscriptionPlan,
  };
};
