import {IRootStoreState} from '../../config/types';
import {errorMessageSf} from '@wix/bi-logger-ec-sf/v2';

export const validateStateInputs = (state: IRootStoreState, flowApi): void => {
  const errors = state.userInputErrors;
  const origin = 'Product Page';
  if (errors.selection.some(Boolean)) {
    void flowApi.bi.report(
      errorMessageSf({
        isBlocks: true,
        origin,
        fieldName: 'Selection',
      }),
    );
    throw Error('invalid option selection input');
  }

  if (errors.text.some(Boolean)) {
    void flowApi.bi.report(
      errorMessageSf({
        isBlocks: true,
        origin,
        fieldName: 'Custom text field',
      }),
    );
    throw Error('invalid custom text input');
  }

  if (errors.quantity.some(Boolean)) {
    void flowApi.bi.report(
      errorMessageSf({
        isBlocks: true,
        origin,
        fieldName: 'Quantity',
      }),
    );
    throw Error('invalid quantity input');
  }

  if (errors.subscriptionPlan.some(Boolean)) {
    void flowApi.bi.report(
      errorMessageSf({
        isBlocks: true,
        origin,
        fieldName: 'Subscription',
      }),
    );
    throw Error('invalid subscription plan input');
  }
};
