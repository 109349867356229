import {RootStore} from './RootStore';
import {IRootStoreState, StoreSettings} from '../config/types';
import {ContextualProduct} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/types';
import {computed, makeAutoObservable, observable} from 'mobx';
import {getSelectionIds} from '../services/user-input/getUserInputSelectionIds';
import {getQuantityRange} from '../services/product-service/stock/getQuantityRange';
import {calcSelectionsAvailability} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {
  actualPrice,
  actualSku,
  inStock,
  isPreOrder,
  isSelectedSubscriptionOneTimePurchase,
  isSubscribeNow,
  validateUserInputs,
  hasAnyDiscount,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {getPriceFields, getPriceFieldsOld} from '../services/product-service/pricing/getPriceFields';
import {getSubscriptionPlanList} from '../services/product-service/subscriptions/getSubscriptionPlanList';
import {SiteStructure} from '@wix/yoshi-flow-editor';
import {IProduct} from '../../../types/type';
import {getUserInput} from '../services/user-input/getUserInput';
import {getProductOptionsItems} from '../services/product-service/options/getProductOptionsItems';
import {getOptionSelections} from '../services/user-input/getOptionSelections';
import {getOptionSelectionItems} from '../services/user-input/getOptionSelectionItems';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {ReferringPageInfo} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/sitemap/sitemapUtils.types';
import {getCalculatedProductPrice, getQuantity} from '../../../services/cashier-checkout-service';
import {STORES_APP_DEF_ID} from '../../../constants/app';
import {SPECS} from '../../../specs';
import {isRicoRichContent} from '../tests/utils/RichContentViewerUtils';

export type StateInitialData = {
  product: IProduct;
  absoluteProductUrl: string;
  isCollectingBackInStockRequests: boolean;
  contextualProducts: ContextualProduct[];
  storeSettings: StoreSettings;
  siteStructure: SiteStructure;
  referringPage: ReferringPageInfo;
};

export class RootStoreState implements IRootStoreState {
  public readonly product: IProduct;
  public readonly absoluteProductUrl: string;
  public readonly isCollectingBackInStockRequests: boolean;
  public readonly contextualProducts: ContextualProduct[];
  public readonly storeSettings: StoreSettings;
  public readonly siteStructure: SiteStructure;
  public readonly referringPage: ReferringPageInfo;

  public selectedVariant: IOptionSelectionVariant = undefined;
  public quantityInput = 1;
  public textInput = [undefined, undefined];
  public selectionInput = {};
  public subscriptionInput = null;
  public isProductSubmitted = false;
  public isAddToCartInProgress = false;

  constructor(private readonly rootStore: RootStore, initialData: StateInitialData) {
    this.product = initialData.product;
    this.absoluteProductUrl = initialData.absoluteProductUrl;
    this.isCollectingBackInStockRequests = initialData.isCollectingBackInStockRequests;
    this.contextualProducts = initialData.contextualProducts;
    this.storeSettings = initialData.storeSettings;
    this.siteStructure = initialData.siteStructure;
    this.referringPage = initialData.referringPage;
    makeAutoObservable(this, {
      product: observable.shallow,
      selectionIds: computed.struct,
      selectionAvailability: computed.struct,
      optionItems: computed.struct,
      userInput: computed.struct,
      userInputErrors: computed.struct,
    });
  }

  private get subscriptionPlanList() {
    return getSubscriptionPlanList(this);
  }

  private get priceInfo() {
    return this.rootStore.isExperimentEnabled(SPECS.FixPricingMismatch)
      ? getPriceFields(this.rootStore)
      : getPriceFieldsOld(this.rootStore);
  }

  public get selectionIds() {
    return getSelectionIds(this, this.rootStore.isExperimentEnabled(SPECS.SelectionIdsShouldStartFromZero));
  }

  public get variantId() {
    return this.selectedVariant?.id || this.product.productItems[0]?.id;
  }

  public get maxQuantity() {
    return getQuantityRange(this).max;
  }

  public get shouldUseRicoDescription() {
    return (
      this.rootStore.isExperimentEnabled(SPECS.PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS) &&
      isRicoRichContent(this.product.description)
    );
  }

  public get minQuantity() {
    return getQuantityRange(this).min;
  }

  public get inStock() {
    return inStock(this.product, this.selectedVariant);
  }

  public get actualPrice() {
    return actualPrice(this.product, this.selectedVariant);
  }

  public get calculatedPrice() {
    const actualProduct = this.selectedVariant || this.product;
    return getCalculatedProductPrice(actualProduct, getQuantity(this.userInput));
  }

  public get actualSku() {
    return actualSku(this.product, this.selectedVariant);
  }

  public get isPreOrder() {
    return isPreOrder(this.product, this.selectedVariant);
  }

  public get selectionAvailability() {
    return calcSelectionsAvailability({
      product: this.product,
      variantSelectionIds: this.selectionIds,
    });
  }

  public get optionItems() {
    return getProductOptionsItems(this.rootStore);
  }

  public get selectionItems() {
    return getOptionSelectionItems(this);
  }

  public get optionSelections() {
    return getOptionSelections(this);
  }

  public get userInput() {
    return getUserInput(this);
  }

  public get userInputErrors() {
    return validateUserInputs(this.product, this.userInput);
  }

  public get showNotifyMe() {
    return !this.inStock && this.isCollectingBackInStockRequests;
  }

  public get isOneTimePurchase() {
    return isSelectedSubscriptionOneTimePurchase(this.subscriptionInput);
  }

  public get isSubscribeNow() {
    return isSubscribeNow(this.product, this.subscriptionInput);
  }

  public get hasDiscount() {
    return hasAnyDiscount(this.product);
  }

  public get isAutomatedDiscount() {
    return !!this.product.itemDiscount;
  }

  public get discountTitle() {
    return this.product.itemDiscount?.discountRuleName;
  }

  public get selectedSubscriptionPlan() {
    return this.subscriptionPlanList.find((plan) => plan.id === this.subscriptionInput);
  }

  public get formattedActualPrice() {
    return this.priceInfo.formattedActualPrice;
  }

  public get formattedOriginalPrice() {
    return this.priceInfo.formattedOriginalPrice;
  }

  public get formattedPricePerUnit() {
    return this.priceInfo.formattedPricePerUnit;
  }

  public get formattedOneTimePurchasePrice() {
    return this.priceInfo.formattedOneTimePurchasePrice;
  }

  public get frequency() {
    return this.priceInfo.frequency;
  }

  public get interval() {
    return this.priceInfo.interval;
  }

  public get shouldShowPreOrderMessage() {
    return this.isPreOrder && this.preOrderMessage?.length > 0;
  }

  public get preOrderMessage() {
    return this.product.inventory.preOrderInfoView?.message || this.selectedVariant?.preOrderInfo?.message;
  }

  public get trackParams() {
    return {
      appDefId: STORES_APP_DEF_ID,
      origin: 'Stores',
      id: this.product.id,
      name: this.product.name,
      price: this.actualPrice,
      currency: this.product.currency,
      quantity: getQuantity(this.userInput),
      sku: this.actualSku,
      type: this.product.productType,
      brand: this.product.brand,
    };
  }
}
