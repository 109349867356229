import model from './model';
import {
  InfoSectionItemComponentIds as ItemComponentIds,
  InfoSectionWidgetComponentIds as WidgetComponentIds,
} from './config/constants';
import {IAdditionalInfo} from '../../types/type';
import {action, makeAutoObservable} from 'mobx';
import {clickOnProductDetailsSf} from '@wix/bi-logger-ec-sf/v2';
import {SPECS} from '../../specs';
import {InfoSectionAccordionDefaultState as AccordionDefaultState} from './config/presets';
import {getRicoRichContent} from '../rootWidget/tests/utils/RichContentViewerUtils';
import {fromPlainText} from 'ricos-content/libs/converters';

export default model.createController((controllerParams) => {
  const {$props, $bindAll, flowAPI} = controllerParams;
  const breakpoint = flowAPI.environment.isMobile ? 'mobile' : 'desktop';
  const getInitialOpenedIndex = () => {
    const defaultState = $props.settings.accordionDefaultState?.[breakpoint];
    if (defaultState === AccordionDefaultState.AllCollapsed) {
      return -1;
    }
    if (defaultState === AccordionDefaultState.FirstExpanded) {
      return 0;
    }
    return 0;
  };
  const $widgetState = makeAutoObservable({
    userOpenedIndex: undefined,
    get initialOpenedIndex() {
      return getInitialOpenedIndex();
    },
    get openedIndex() {
      return this.userOpenedIndex ?? this.initialOpenedIndex;
    },
    get currentLayout() {
      return $props.settings.layouts[breakpoint];
    },
    get isCollapseLayout() {
      return this.currentLayout.includes('collapse');
    },
    get isColumnsLayout() {
      return this.currentLayout.includes('column');
    },
    get isCardsLayout() {
      return this.currentLayout.includes('cards');
    },
  });

  const toggleItem = action((itemIndex: number) => {
    $widgetState.userOpenedIndex =
      $widgetState.openedIndex > -1 && $widgetState.openedIndex === itemIndex ? -1 : itemIndex;
    void flowAPI.bi.report(
      clickOnProductDetailsSf({
        isBlocks: true,
      }),
    );
  });

  return {
    pageReady: () => {
      $bindAll({
        [WidgetComponentIds.Repeater]: {
          data: () => $props.sections,
          item: (itemData: IAdditionalInfo) => {
            const $itemState = makeAutoObservable({
              get index() {
                return $props.sections.findIndex((c) => c._id === itemData._id);
              },
              get isOpen() {
                return $itemState.index > -1 && $widgetState.openedIndex === $itemState.index;
              },
              get isLastItem() {
                return $itemState.index === $props.sections.length - 1;
              },
              get data() {
                return $props.sections[$itemState.index];
              },
              get title() {
                return $itemState.data?.title;
              },
              get shouldUseRicoInfoSection() {
                return (
                  flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS) &&
                  $itemState.data?.isRico
                );
              },
              get ricoDescription() {
                return getRicoRichContent($itemState.data?.description);
              },
              get description() {
                return $itemState.data?.description;
              },
            });
            return {
              [ItemComponentIds.Title]: {
                text: () => $itemState.title ?? '',
              },
              [ItemComponentIds.TitleBox]: {
                onClick: () => $widgetState.isCollapseLayout && toggleItem($itemState.index),
              },
              [ItemComponentIds.Paragraph]: {
                // ts-expect-error: yoshi types issue
                richText: () => $itemState.description ?? '',
                collapsed: () => $widgetState.isCollapseLayout && !$itemState.isOpen,
                deleted: () => $itemState.shouldUseRicoInfoSection,
              },
              [ItemComponentIds.RicoParagraph]: {
                content: () => $itemState.ricoDescription ?? fromPlainText(''),
                deleted: () => !$itemState.shouldUseRicoInfoSection,
                collapsed: () => $widgetState.isCollapseLayout && !$itemState.isOpen,
              },
              [ItemComponentIds.SeparatorLineHorizontal]: {
                deleted: () => $widgetState.isColumnsLayout || $widgetState.isCardsLayout || $itemState.isLastItem,
              },
              [ItemComponentIds.SeparatorLineVertical]: {
                deleted: () => !$widgetState.isCardsLayout && (!$widgetState.isColumnsLayout || $itemState.isLastItem),
              },
              [ItemComponentIds.PlusIcon]: {
                hidden: () => $widgetState.isCollapseLayout && $itemState.isOpen,
              },
              [ItemComponentIds.MinusIcon]: {
                hidden: () => $widgetState.isCollapseLayout && !$itemState.isOpen,
              },
            };
          },
        },
      });
    },
    exports: {},
  };
});
