import {RootStore} from '../../stores/RootStore';
import {SPECS} from '../../../../specs';

export const getSlugFromPlatform = (rootStore: RootStore): string => {
  const path = rootStore.controllerParams.controllerConfig.wixCodeApi.location.path;

  if (rootStore.controllerParams.$widget.props.slug) {
    return rootStore.controllerParams.$widget.props.slug;
  }

  if (rootStore.controllerParams.flowAPI.experiments.enabled(SPECS.ProductPageBlocksSlugFix)) {
    // TODO:Zeev: make this pretty
    if (rootStore.controllerParams.flowAPI.environment.isViewer) {
      if (path.length) {
        return path[path.length - 1];
      }
    } else {
      if (path.length > 1) {
        return path[path.length - 1];
      }
    }
  } else {
    if (path.length > 1) {
      return path[path.length - 1];
    }
  }

  return null;
};
