export enum ColorWidgetComponentIds {
  Repeater = '#repeater',
  Label = '#label',
  TopContainer = '#box1',
  ErrorMessage = '#errorMessage',
}

export const ColorItemComponentIds = {
  SelectedCircle: '#selectedCircle',
  DefaultCircle: '#defaultCircle',
  ErrorCircle: '#errorCircle',
  DefaultCrossLine: '#defaultCrossLine',
  SelectedCrossLine: '#selectedCrossLine',
  ErrorCrossLine: '#errorCrossLine',
  ColorFill: '#colorFill',
  ItemWrapper: '#colorItem',
  RadioCircle: '#radioButtonCircle',
} as const;

export enum ColorPickerWidgetEvents {
  Change = 'change',
}
