import model from './model';
import {CustomTextFieldsComponentIds as ComponentIds, CustomTextFieldsWidgetEvents as Events} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {makeAutoObservable} from 'mobx';
import {clickOnFreeText} from '@wix/bi-logger-ec-sf/v2';

export default model.createController((controllerParams) => {
  const {$w, $widget, $bindAll, $props, flowAPI} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const reportBi = () => {
    void flowAPI.bi.report(
      clickOnFreeText({
        isBlocks: true,
      }),
    );
  };

  const handleFreeTextBoxFocus = () => {
    reportBi();
  };

  return {
    pageReady: () => {
      [0, 1].forEach((index) => {
        const $fieldState = makeAutoObservable({
          get value() {
            return $fieldState.data?.value ?? '';
          },
          get length() {
            return $fieldState.value.length;
          },
          get data() {
            if (index < $props.fields.length) {
              return $props.fields[index];
            }
          },
          get isRequired() {
            return !!$fieldState.data?.required;
          },
          get isError() {
            return !!$fieldState.data?.validation?.isError;
          },
          get title() {
            return $fieldState.data?.title ?? '';
          },
          get maxLength(): number | undefined {
            return $fieldState.data?.maxLength ?? 99999;
          },
        });
        const FieldIds = ComponentIds[index];
        $bindAll({
          [FieldIds.FieldWrapper]: {
            deleted: () => !$fieldState.data,
          },
          [FieldIds.Label]: {
            text: () =>
              $fieldState.isRequired
                ? $fieldState.title
                : t.productPage.customTextWidget.optional.label({customTextTitle: $fieldState.title}),
            onClick: () => {
              handleFreeTextBoxFocus();
              $w(FieldIds.TextBox).focus();
            },
            accessibility: {
              screenReader: {
                suffix: () =>
                  t.productPage.sr.customTextWidget.maxCharacters({
                    numCharacters: $fieldState.data?.maxLength,
                  }),
              },
            },
          },
          [FieldIds.TextBox]: {
            onFocus: handleFreeTextBoxFocus,
            maxLength: () => $fieldState.maxLength,
            required: () => ($fieldState.isRequired && $fieldState.isError) || false,
            onInput: (evt: $w.Event) => {
              $widget.fireEvent(Events.Change, {customTextField: evt.target.value, index});
            },
            accessibility: {
              ariaAttributes: {
                labelledBy: () => $w(FieldIds.Label),
                describedBy: () => $w(FieldIds.Error).getTextSelector(),
              },
            },
            value: () => $fieldState.value,
          },
          [FieldIds.CharCounter]: {
            text: () => `${$fieldState.length} / ${$fieldState.maxLength}`,
          },
          [FieldIds.Error]: {
            deleted: () => !$fieldState.isError,
            text: () => $fieldState.data?.validation?.errorMessage ?? '',
          },
        });
      });
    },
    exports: {
      focus: (index: 0 | 1) => {
        $w(ComponentIds[index].TextBox).focus();
      },
    },
  };
});
