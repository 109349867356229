import {getProductsMediaItems} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {mediaToGalleryItem} from '../../../mappers/mapMediaToGalleryItem';
import {IRootStoreState} from '../../../config/types';

const noMediaPlaceholder = {
  src: 'https://static.wixstatic.com/media/a2223b_42eb74a8f7034d78bd4afb8521996e71~mv2.png#originWidth=1782&originHeight=1782',
  title: 'Media Placeholder',
  alt: 'Media Placeholder',
  type: 'Image',
} as $w.Gallery.ImageItem;

export const getMediaItems = (state: IRootStoreState): ($w.Gallery.ImageItem | $w.Gallery.VideoItem)[] => {
  return state.product.media?.length > 0
    ? getProductsMediaItems({product: state.product, variantSelectionIds: state.selectionIds}).map(mediaToGalleryItem)
    : [noMediaPlaceholder];
};
