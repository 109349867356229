import {RootStore} from '../stores/RootStore';
import {getOrigin} from './platform-service/getOrigin';
import {ProductType} from '@wix/wixstores-client-core/dist/es/src/types';
import {mapUserInputSelectionMapToChoices} from '../mappers/mapUserInputSelectionMapToChoices';
import {CustomTextOptionInput} from '@wix/wixstores-client-storefront-sdk/dist/es/src/__generated__/schemaTypes';
import {IRootStoreState} from '../config/types';
import {getCurrentLanguage, getDeviceType} from '../../../services/platform-service';

const getCustomTextFieldSelection = (rootStore: RootStore): CustomTextOptionInput[] => {
  return rootStore.$state.textInput.reduce((fields, value, index) => {
    if (value) {
      const {isMandatory, key, title} = rootStore.$state.product.customTextFields[index];
      fields.push({title, isMandatory, key, value});
    }
    return fields;
  }, []);
};

const getActualSubscriptionPlanId = (state: IRootStoreState): string => {
  return state.isOneTimePurchase ? undefined : state.subscriptionInput;
};

export const createCheckout = (
  rootStore: RootStore,
  {withNavigateToCheckout, isPreOrder}: {withNavigateToCheckout: boolean; isPreOrder?: boolean},
) => {
  const {
    wixStoresApi: wixstoresApi,
    $state: {product, quantityInput},
  } = rootStore;
  return wixstoresApi.checkout.handleDirectPurchase({
    productId: product.id,
    productType: product.productType as ProductType,
    quantity: quantityInput,
    customTextFieldSelection: getCustomTextFieldSelection(rootStore),
    deviceType: getDeviceType(rootStore.wixCodeApi),
    originType: getOrigin(rootStore),
    variantId: rootStore.$state.selectedVariant ? rootStore.$state.variantId : undefined,
    optionSelectionId: rootStore.$state.selectionIds,
    options: mapUserInputSelectionMapToChoices(rootStore),
    subscriptionOptionId: getActualSubscriptionPlanId(rootStore.$state) || undefined,
    locale: getCurrentLanguage(rootStore.wixCodeApi),
    withNavigateToCheckout,
    preOrderRequested: isPreOrder,
  });
};
