import model from './model';
import {NavigationWidgetComponentIds as ComponentIds} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {clickOnNavigation} from '@wix/bi-logger-ec-sf/v2';

export default model.createController((controllerParams) => {
  const {$props, $bindAll, flowAPI, $w} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const reportBi = () => {
    void flowAPI.bi.report(
      clickOnNavigation({
        component: 'Prev/Next',
        isBlocks: true,
      }),
    );
  };
  const handleNavigationClick = (componentClicked) => {
    reportBi();
    $w(componentClicked).focus();
  };
  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.nextIcon]: {
          disabled: () => !$props.data.nextLink,
          link: () => $props.data.nextLink ?? '',
          onClick: /* istanbul ignore next reason: tested in sled */ () => handleNavigationClick(ComponentIds.nextIcon),
          label: () => t.productPage.nextButton.text(),
        },
        [ComponentIds.prevIcon]: {
          disabled: () => !$props.data.prevLink,
          link: () => $props.data.prevLink ?? '',
          onClick: /* istanbul ignore next reason: tested in sled */ () => handleNavigationClick(ComponentIds.prevIcon),
          label: () => t.productPage.previousButton.text(),
        },
        [ComponentIds.topContainer]: {
          accessibility: {
            ariaAttributes: {
              label: () => t.productPage.sr.navigationWidget.container.name(),
            },
            role: () => 'navigation',
          },
        },
      });
    },
    exports: {},
  };
});
