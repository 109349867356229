export const CustomTextFieldsComponentIds = [
  {
    FieldWrapper: '#fieldWrapper1',
    Label: '#customTextFieldLabel1',
    TextBox: '#customTextTextBox1',
    CharCounter: '#customTextCharCounter1',
    Error: '#errorMessage1',
  } as const,
  {
    FieldWrapper: '#fieldWrapper2',
    Label: '#customTextFieldLabel2',
    TextBox: '#customTextTextBox2',
    CharCounter: '#customTextCharCounter2',
    Error: '#errorMessage2',
  } as const,
];

export const CustomTextFieldTopContainerComponentId = '#topContainer';

export enum CustomTextFieldsWidgetEvents {
  Change = 'change',
}
