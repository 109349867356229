import {ISubscriptionPlan} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSubscriptionService/type';
import {initLocaleKeys} from '../../../../../services/platform-service/initLocaleKeys';
import {RootStore} from '../../../stores/RootStore';

export const getSubscriptionDetailsTranslation = ({
  rootStore,
  plan,
}: {
  rootStore: RootStore;
  plan: ISubscriptionPlan;
}): string => {
  const t = initLocaleKeys(rootStore.controllerParams.flowAPI.translations.i18n);

  if (!plan) {
    return;
  }

  const {interval, frequency, duration} = plan;
  if (frequency === 'DAY') {
    return 'daily for QA';
  }
  const frequencyUnitForKeys = `${frequency.toLowerCase()}s`;

  const getIntervalTranslation = (frequencyUnit: string) => {
    return t.productPage.subscriptionsWidget.interval[frequencyUnit]({
      interval,
    });
  };

  const getDurationTranslation = (frequencyUnit: string) => {
    if (!duration) {
      return t.productPage.subscriptionsWidget.duration.noExpiration();
    }
    return t.productPage.subscriptionsWidget.duration[frequencyUnit]({
      duration,
    });
  };

  return t.productPage.subscriptionsWidget.frequencyDuration.container({
    interval: getIntervalTranslation(frequencyUnitForKeys),
    duration: getDurationTranslation(frequencyUnitForKeys),
  });
};
