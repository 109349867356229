import {PriceSettings} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ILocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StoreSettings} from '../../../config/types';

import {RootStore} from '../../../stores/RootStore';

const getTaxDisclaimer = (storeSettings: StoreSettings, t: ILocaleKeys) => {
  const {localeTaxName, includeTaxOnProduct} = storeSettings.tax;
  if (!localeTaxName) {
    return undefined;
  }

  if (includeTaxOnProduct) {
    return t.productPage.price.tax.includedParam.label({
      taxOrVAT: localeTaxName,
      included: t.productPage.price.tax.included.label(),
    });
  } else {
    return t.productPage.price.tax.excludedParam.label({
      taxOrVAT: localeTaxName,
      excluding: t.productPage.price.tax.excluded.label(),
    });
  }
};

export const getPriceBreakdown = (
  rootStore: RootStore,
): {taxDisclaimerText: string; showTaxDisclaimer: boolean; shippingDisclaimer: PriceSettings['shippingDisclaimer']} => {
  const {localeTaxName, showTaxDisclaimer} = rootStore.$state.storeSettings.tax;
  const shippingSettings = rootStore.$state.storeSettings.shipping;

  return {
    taxDisclaimerText: getTaxDisclaimer(rootStore.$state.storeSettings, rootStore.t),
    showTaxDisclaimer: showTaxDisclaimer && !!localeTaxName,
    shippingDisclaimer: shippingSettings.shippingDisclaimer,
  };
};
