import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'customTextFieldsWidget',
  props: {
    fields: {
      type: null,
      defaultValue: [],
    },
  },
  methods: {
    focus: {},
  },
  events: {
    change: {},
  },
});
