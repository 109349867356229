import {IWixAPI} from '@wix/yoshi-flow-editor';

export const getCurrentLanguage = (wixCodeApi: IWixAPI): string => {
  const {window, site} = wixCodeApi;
  if (window.multilingual.isEnabled) {
    return window.multilingual.currentLanguage;
  }

  return site.language;
};
