import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {StockIndicatorWidgetProps as Props} from './config/types';

const DEFAULT_DATA: Props['data'] = {
  amountInStock: 1,
};

const DEFAULT_SETTINGS: Props['settings'] = {
  threshold: 10,
  activeState: null,
  outOfStockText: null,
  showStockIndicator: true,
};

export default createBlocksModel({
  widgetName: 'stockIndicatorWidget',
  props: {
    data: {
      type: undefined,
      title: 'Stock Indicator Data Properties',
      defaultValue: DEFAULT_DATA,
      description: 'Property for Stock Indicator',
    },
    settings: {
      type: undefined,
      title: 'Stock Indicator Settings Properties',
      defaultValue: DEFAULT_SETTINGS,
      description: 'Settings for Stock Indicator',
    },
  },
  methods: {},
  events: {},
});
