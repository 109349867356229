import {TFunction} from '../../../../../types/type';
import {IRootStoreState} from '../../../config/types';
import {unitsTranslations} from '../unitTranslations';

export const getPricePerUnitData = (
  rootState: IRootStoreState,
  t: TFunction,
): {
  showPricePerUnit: boolean;
  formattedUnitAndQuantity: {abbr: string; full: string};
} => {
  const getUnitTranslations = (quantity: number, baseMeasurementUnit: string, isAbbreviation: boolean): string => {
    if (isAbbreviation) {
      return t(unitsTranslations[baseMeasurementUnit].abbr);
    } else {
      return quantity === 1
        ? t(unitsTranslations[baseMeasurementUnit].singular)
        : t(unitsTranslations[baseMeasurementUnit].plural);
    }
  };

  const getFormattedUnitAndQuantity = (
    pricePerUnitData: IRootStoreState['product']['pricePerUnitData'],
    isAbbreviation: boolean,
  ): string => {
    if (pricePerUnitData) {
      const {baseQuantity, baseMeasurementUnit} = pricePerUnitData;
      if (isAbbreviation) {
        const abbrUnitTranslation = getUnitTranslations(baseQuantity, baseMeasurementUnit, true);
        return `${baseQuantity}${abbrUnitTranslation}`;
      } else {
        const fullUnitTranslation = getUnitTranslations(baseQuantity, baseMeasurementUnit, false);
        return `${baseQuantity} ${fullUnitTranslation}`;
      }
    }
  };

  return {
    showPricePerUnit: !!rootState.product.pricePerUnitData,
    formattedUnitAndQuantity: {
      abbr: getFormattedUnitAndQuantity(rootState.product.pricePerUnitData, true),
      full: getFormattedUnitAndQuantity(rootState.product.pricePerUnitData, false),
    },
  };
};
