import {IRootStoreState} from '../../../config/types';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {MAX_QUANTITY_INPUT, MIN_QUANTITY_INPUT} from '../../../config/constants';

export const getQuantityRange = (state: IRootStoreState): {max: number; min: number} => {
  const quantities = QuantityCalculator.getQuantitiesRange(state.product, state.optionSelections);
  return {
    max: quantities.length > 0 ? quantities[quantities.length - 1] : MAX_QUANTITY_INPUT,
    min: quantities[0] ?? MIN_QUANTITY_INPUT,
  };
};
