import {ICustomTextFields} from '@wix/wixstores-client-core/dist/src/types/product';
import {RootStore} from '../stores/RootStore';

export const mapUserInputTextToFields = (rootStore: RootStore): ICustomTextFields[] => {
  return rootStore.$state.product.customTextFields
    .map((field, index: number) => ({
      title: field.title,
      value: rootStore.$state.textInput[index],
    }))
    .filter((field) => field.value);
};
