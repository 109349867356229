import {IProduct} from '../../../types/type';

type MediaItem = IProduct['media'][number];
const enum MediaItemType {
  Video = 'VIDEO',
  Image = 'PHOTO',
}

const buildImageUrl = (mediaItem: MediaItem): string => {
  return `wix:image://v1/${mediaItem.url}/${mediaItem.title || ''}#originWidth=${mediaItem.width}&originHeight=${
    mediaItem.height
  }`;
};

const buildImage = (mediaItem: MediaItem): $w.Gallery.ImageItem => {
  return {
    src: buildImageUrl(mediaItem),
    title: mediaItem.altText || '',
    alt: mediaItem.altText || '',
    type: 'Image',
  } as $w.Gallery.ImageItem;
};

const buildThumbnailUrl = (mediaItem: MediaItem): string => {
  return buildImageUrl({...mediaItem, title: 'file.jpg', width: 50, height: 50});
};

const buildVideoUrl = (mediaItem: MediaItem, videoFile: MediaItem['videoFiles'][number]): string => {
  const posterHeight = videoFile.height || 100;
  const posterWidth = videoFile.width || 100;

  return `wix:video://v1/${mediaItem.id}/file.${videoFile.format}#posterUri=${mediaItem.url}&posterWidth=${posterWidth}&posterHeight=${posterHeight}`;
};

const buildVideo = (mediaItem: MediaItem): $w.Gallery.VideoItem => {
  const videoFile = mediaItem.mediaType === MediaItemType.Video && mediaItem.videoFiles && mediaItem.videoFiles[0];
  return {
    src: buildVideoUrl(mediaItem, videoFile),
    type: 'Video',
    title: mediaItem.altText || '',
    alt: mediaItem.altText || '',
    thumbnail: buildThumbnailUrl(mediaItem),
  } as $w.Gallery.VideoItem;
};

export const mediaToGalleryItem = (mediaItem: MediaItem): $w.Gallery.ImageItem | $w.Gallery.VideoItem => {
  if (mediaItem.mediaType === MediaItemType.Video) {
    return buildVideo(mediaItem);
  } else {
    return buildImage(mediaItem);
  }
};
