import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {InfoSectionLayouts} from './config/types';
import {InfoSectionAccordionDefaultState as AccordionDefaultState} from './config/presets';

const DEFAULT_SETTINGS = {
  layout: InfoSectionLayouts.CollapseLeft,
  layouts: {
    desktop: InfoSectionLayouts.CollapseLeft,
    mobile: InfoSectionLayouts.CollapseLeft,
  },
  accordionDefaultState: {
    desktop: AccordionDefaultState.FirstExpanded,
    mobile: AccordionDefaultState.FirstExpanded,
  },
};

export default createBlocksModel({
  widgetName: 'infoSectionsWidget',
  props: {
    sections: {
      type: undefined,
      defaultValue: [],
    },
    settings: {
      type: undefined,
      title: 'Settings property',
      defaultValue: DEFAULT_SETTINGS,
      description: 'Property for Additional Info Sections',
    },
  },
  methods: {},
  events: {},
});
