import {IRootStoreState} from '../../../config/types';
import {IProductSubscriptionPlan} from '@wix/wixstores-graphql-schema/dist/src';

export const getSubscriptionPlanList = (rootState: IRootStoreState): IProductSubscriptionPlan[] => {
  if (rootState.selectedVariant) {
    return rootState.selectedVariant.subscriptionPlans?.list || [];
  } else {
    return rootState.product.subscriptionPlans?.list || [];
  }
};
