import {RootStore} from '../stores/RootStore';
import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import {makeAutoObservable, observable} from 'mobx';
import {mapUserInputSelectionMapToChoices} from '../mappers/mapUserInputSelectionMapToChoices';
import {handleQuantityChange} from './handlers/handleQuantityChange';

export type OverrideCallback = (resume: (a: unknown) => void, cancel: (a: unknown) => void) => void;
export type OnChoiceSelectedCallbackType = (choices: ReturnType<typeof mapUserInputSelectionMapToChoices>) => void;
export type OnQuantityChangedCallbackType = (value: number) => void;

export class RootWidgetApiService {
  public onAddToCartInterruption: any = null;
  public onBuyNowInterruption: any = null;
  public labelOverrides = {
    cta: {
      addToCart: null,
      buyNow: null,
    },
  };

  public onChoiceSelectedCallback: OnChoiceSelectedCallbackType = null;
  public onQuantityChangedCallback: OnQuantityChangedCallbackType = null;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this, {
      labelOverrides: observable.deep,
    });
  }

  public getProductWixCode() {
    return this.rootStore.reportFedopsInteraction(FedopsInteractions.WixCodeGetProduct, () =>
      // @ts-expect-error 'data' is coming in runtime
      this.rootStore.wixCodeApi.data.get('Stores/Products', this.rootStore.$state.product.id),
    );
  }

  public getCustomText() {
    return this.rootStore.reportFedopsInteraction(FedopsInteractions.WixCodeGetCustomTextFieldValues, () =>
      this.rootStore.$state.userInput.text.map((text) => (text ? text : '')),
    );
  }

  public addToCartOverride(callback: OverrideCallback) {
    this.onAddToCartInterruption = () =>
      new Promise((resolve, reject) => {
        callback(resolve, () => reject('cancel'));
      });
  }

  public onBuyNowOverride(callback: OverrideCallback) {
    this.onBuyNowInterruption = () =>
      new Promise((resolve, reject) => {
        callback(resolve, () => reject('cancel'));
      });
  }

  public setAddToCartLabel(value: string) {
    this.labelOverrides.cta.addToCart = value;
  }

  public setBuyNowLabel(value: string) {
    this.labelOverrides.cta.buyNow = value;
  }

  public onChoiceSelected(callback: OnChoiceSelectedCallbackType) {
    this.onChoiceSelectedCallback = callback;
  }

  public getSelectedVariantId(): string {
    return this.rootStore.$state?.selectedVariant?.id;
  }

  public getSelectedChoices(): ReturnType<typeof mapUserInputSelectionMapToChoices> {
    return mapUserInputSelectionMapToChoices(this.rootStore);
  }
  public getQuantity() {
    return this.rootStore.$state.quantityInput;
  }

  public setQuantity(value: number) {
    handleQuantityChange(this.rootStore)({data: value});
  }

  public onQuantityChanged(callback: OnQuantityChangedCallbackType) {
    this.onQuantityChangedCallback = callback;
  }
}
