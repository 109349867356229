import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {IProductData} from '../../types/wixDataTypes';
import {methods} from './config/exportedMethods';

const DEFAULT_SETTINGS = {
  desktop: {
    alignment: 'left',
  },
  mobile: {
    alignment: 'left',
  },
};

export default createBlocksModel({
  widgetName: 'rootWidget',
  props: {
    productId: {
      type: undefined,
      defaultValue: undefined as string,
      description: 'Property for productId',
    },
    slug: {
      type: undefined,
      defaultValue: undefined as string,
      description: 'Property for slug',
    },
    productObject: {
      type: undefined,
      defaultValue: undefined as IProductData,
      description: 'Property for productObject',
    },
    layoutSettings: {
      type: undefined,
      title: 'Layout settings property',
      defaultValue: DEFAULT_SETTINGS,
      description: 'Property for alignment settings',
    },
  },
  methods,
  events: {},
});
