import {ColorPickerItemComponents} from '../config/types';
import {IWixAPI} from '@wix/yoshi-flow-editor';

export class AnimationService {
  private readonly itemAnimations: Record<string, any> = {};
  constructor(private readonly wixAnimations: IWixAPI['animations']) {}

  public storeItemAnimation(itemId: string, itemComponents: ColorPickerItemComponents): void {
    const {colorFill, selectedCircle, defaultCircle} = itemComponents;
    const shrinkColorFill = this.wixAnimations.timeline();
    shrinkColorFill.add(colorFill, {
      scale: 0.7,
      duration: 100,
    });
    const hideDefaultCircle = this.wixAnimations.timeline();
    hideDefaultCircle.add(defaultCircle, {
      opacity: 0.01,
      duration: 10,
    });
    const hideSelectedCircle = this.wixAnimations.timeline();
    hideSelectedCircle.add(selectedCircle, {
      opacity: 0.01,
      duration: 100,
    });
    const fadeSelectedCircle = this.wixAnimations.timeline();
    fadeSelectedCircle.add(selectedCircle, {
      opacity: 0.6,
      duration: 100,
    });
    this.itemAnimations[itemId] = {shrinkColorFill, hideDefaultCircle, fadeSelectedCircle, hideSelectedCircle};
  }
  public playItemSelection(itemId: string): void {
    const itemAnimation = this.itemAnimations[itemId];
    itemAnimation.fadeSelectedCircle.reverse();
    itemAnimation.hideSelectedCircle.play();
    itemAnimation.hideDefaultCircle.reverse();
    itemAnimation.shrinkColorFill.reverse();
  }

  public reverseItemSelection(itemId: string): void {
    const itemAnimation = this.itemAnimations[itemId];
    itemAnimation.hideDefaultCircle.play();
    itemAnimation.hideSelectedCircle.reverse();
    itemAnimation.shrinkColorFill.play();
    itemAnimation.fadeSelectedCircle.play();
  }

  /* istanbul ignore next reason: there is no animations testkit yet */
  public playItemHover(itemId: string, itemComponents: ColorPickerItemComponents): void {
    const itemAnimation = this.itemAnimations[itemId];
    !itemComponents.selectedCircle.hidden && itemAnimation.hideDefaultCircle.play();
    !itemComponents.defaultCircle.hidden && itemAnimation.hideSelectedCircle.reverse();
    itemAnimation?.shrinkColorFill.play();
  }

  /* istanbul ignore next reason: there is no animations testkit yet */
  public reverseItemHover(itemId: string, itemComponents: ColorPickerItemComponents): void {
    const itemAnimation = this.itemAnimations[itemId];
    !itemComponents.selectedCircle.hidden && itemAnimation.hideSelectedCircle.play();
    !itemComponents.defaultCircle.hidden && itemAnimation.hideDefaultCircle.reverse();
    itemAnimation?.shrinkColorFill.reverse();
  }
}
