import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {DropdownWidgetEvents} from './config/constants';

export default createBlocksModel({
  widgetName: 'dropdownWidget',
  props: {
    shouldShowTitle: {
      type: 'boolean',
      defaultValue: true,
    },
    label: {
      type: 'text',
      defaultValue: '',
    },
    selections: {
      type: undefined,
      defaultValue: [],
    },
    selectionIds: {
      type: undefined,
      defaultValue: [],
    },
    isError: {
      type: 'boolean',
      defaultValue: false,
    },
    errorMessage: {
      type: 'text',
      defaultValue: '',
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [DropdownWidgetEvents.Change]: {
      description: '',
    },
  },
});
