import type {RichContent} from 'ricos-schema/dist/types/stringEnums/wix/rich_content/v1';
import {isRichContent} from 'ricos-content';

export const isRicoRichContent = (content: string) => {
  return getRicoRichContent(content) !== null;
};
export const getRicoRichContent = (content: string) => {
  try {
    const richContent = JSON.parse(content) as RichContent;
    return isRichContent(richContent) ? richContent : null;
  } catch {
    return null;
  }
};
