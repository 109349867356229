import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'socialShareWidget',
  props: {
    facebookLink: {
      type: 'text',
      title: 'Facebook Link',
      defaultValue: undefined,
    },
    pinterestLink: {
      type: 'text',
      title: 'Pinterest Link',
      defaultValue: undefined,
    },
    twitterLink: {
      type: 'text',
      title: 'Twitter Link',
      defaultValue: undefined,
    },
    whatsappLink: {
      type: 'text',
      title: 'Whatsapp Link',
      defaultValue: undefined,
    },
  },
  methods: {},
  events: {},
});
