export const waitUntilReadyFactory = (): {onReadyResolver: (value: any) => void; waitUntilReady: Promise<any>} => {
  let onReadyResolver: (value: any) => void;
  const waitUntilReady = new Promise((resolve) => {
    onReadyResolver = resolve;
  });

  return {
    onReadyResolver,
    waitUntilReady,
  };
};

export const getParamValueFromUrlByParamKey = (url: string, keyWord: string) => {
  const index = url.indexOf(keyWord);
  const substring = url.slice(index + keyWord.length);
  const ampersandIndex = substring.indexOf('&');
  return ampersandIndex === -1 ? substring : substring.substring(0, ampersandIndex);
};
