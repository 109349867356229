import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'priceWidget',
  props: {
    formattedActualPrice: {
      type: 'text',
      defaultValue: '',
    },
    formattedOriginalPrice: {
      type: 'text',
      defaultValue: '',
    },
    formattedPricePerUnit: {
      type: 'text',
      defaultValue: '',
    },
    formattedUnitAndQuantity: {
      type: 'text',
      defaultValue: '',
    },
    srFormattedUnitAndQuantity: {
      type: 'text',
      defaultValue: '',
    },
    frequencyLabel: {
      type: 'text',
      defaultValue: '',
    },
    showPriceRange: {
      type: 'boolean',
      defaultValue: false,
    },
    showPricePerUnit: {
      type: 'boolean',
      defaultValue: false,
    },
    showDiscount: {
      type: 'boolean',
      defaultValue: false,
    },
    showTaxDisclaimer: {
      type: 'boolean',
      defaultValue: false,
    },
    taxDisclaimerText: {
      type: 'text',
      defaultValue: '',
    },
    showShippingDisclaimer: {
      type: 'boolean',
      defaultValue: false,
    },
    showShippingDisclaimerModal: {
      type: 'boolean',
      defaultValue: false,
    },
    shippingDisclaimerText: {
      type: 'text',
      defaultValue: '',
    },
    cashierBannerProps: {
      type: undefined,
      defaultValue: null,
    },
    showDiscountTitle: {
      type: 'boolean',
      defaultValue: false,
    },
    discountTitle: {
      type: 'text',
      defaultValue: '',
    },
  },
  methods: {},
  events: {},
});
