import {createBlocksModel} from '@wix/yoshi-flow-editor';

const DEFAULT_DATA = {
  prevLink: '',
  nextLink: '',
};

export default createBlocksModel({
  widgetName: 'navigationWidget',
  props: {
    data: {
      type: null,
      title: 'Navigation property',
      defaultValue: DEFAULT_DATA,
      description: 'Navigation property',
    },
  },
  methods: {},
  events: {},
});
