import {ProductPriceRange} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceRange/ProductPriceRange';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {RootStore} from '../../../stores/RootStore';

export const getPriceRange = (
  rootStore: RootStore,
): {
  showPriceRange: boolean;
  fromPriceFormatted: string;
} => {
  // TODO - refactor ProductPriceRange to be without site-store dependency
  const partialSiteStore = {
    priceSettings: {showPriceRange: rootStore.$state.storeSettings.price.showPriceRange},
  } as SiteStore;
  const showPriceRange =
    new ProductPriceRange(partialSiteStore).shouldShowPriceRange() &&
    !rootStore.$state.selectedVariant &&
    !rootStore.$state.subscriptionInput &&
    !!rootStore.$state.product.priceRange;
  const fromPriceFormatted = rootStore.$state.product.priceRange?.fromPriceFormatted;
  return {
    showPriceRange,
    fromPriceFormatted,
  };
};
