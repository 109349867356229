import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {OptionsWidgetEvents as Events} from './config/constants';
import {OptionsWidgetProps as Props} from './config/types';

const DEFAULT_SETTINGS: Props['settings'] = {
  shouldShowTitle: true,
};

export default createBlocksModel({
  widgetName: 'optionsNewWidget',
  props: {
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
    productOptions: {
      type: null,
      defaultValue: [],
    },
    selectionIds: {
      type: null,
      defaultValue: [],
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [Events.Change]: {
      description: '',
    },
  },
});
