import {SeoProductBuilder} from '@wix/wixstores-client-core/dist/es/src/builders/SeoItemData.builder';
import {IProduct} from '../../../../types/type';
import {isVideo} from '@wix/wixstores-client-core/dist/src/media/mediaService';
import {RootStore} from '../../stores/RootStore';
import {getIsViewer} from '../platform-service/getIsViewer';

type VideoSeoData = {
  videoThumbnailUrl: string;
  videoDescription: string;
  videoContentUrl: string;
}[];

const getVideoSeoData = (gqlProduct: IProduct): VideoSeoData => {
  return gqlProduct.media.filter(isVideo).map((media) => {
    return {
      videoThumbnailUrl: media.thumbnailFullUrl,
      videoDescription: media.altText,
      videoName: gqlProduct.name,
      videoContentUrl: `https://video.wixstatic.com/${media.videoFiles[0].url}`,
    };
  });
};

const getSeoData = (gqlProduct: IProduct) => {
  return JSON.parse(gqlProduct.seoJson || '{}');
};

export const setSeoTags = (rootStore: RootStore): void => {
  if (!getIsViewer(rootStore)) {
    return;
  }

  const seoData = getSeoData(rootStore.$state.product);
  const pageUrl = rootStore.$state.absoluteProductUrl;
  const productWithUrl = {...rootStore.$state.product, pageUrl};
  const productWithBase = {productPageBaseUrl: pageUrl};
  const productSeo = new SeoProductBuilder(productWithUrl, productWithBase).get();
  const videoData = getVideoSeoData(rootStore.$state.product);
  const legacySeoData = {
    title: rootStore.$state.product.seoTitle,
    description: rootStore.$state.product.seoDescription,
  };

  const itemData = {
    product: productSeo,
    videoData,
    legacySeoData,
  };
  // TODO:Zeev: temp fix for race condition between SEO and react only in sled: https://wix.slack.com/archives/C03C54CDP4Z/p1661082149938469?thread_ts=1660202011.066219&cid=C03C54CDP4Z
  try {
    /* istanbul ignore next reason: tested in SLED */
    return rootStore.controllerParams.controllerConfig.wixCodeApi.seo.renderSEOTags({
      itemType: 'STORES_PRODUCT',
      seoData,
      itemData,
    });
  } catch (e) {
    /* istanbul ignore next reason: temp fix */
    // eslint-disable-next-line no-console
    console.log('Error rendering seo tags', e);
    /* istanbul ignore next reason: temp fix */
    return;
  }
};
