import {IRootStoreState} from '../../config/types';

export const getProductDescription = (state: IRootStoreState): string => {
  const description = state.product.description;

  if (description && !description.startsWith('<p>')) {
    return `<p>${description}</p>`;
  }

  return description;
};
