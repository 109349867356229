import model from './model';
import {
  OptionsWidgetEvents as Events,
  optionsNewWidgetComponentIds as ComponentIds,
  optionsNewWidgetItemComponentIds as ItemComponentIds,
  OptionsStates,
} from './config/constants';
import {ProductOptionType as OptionType} from '@wix/wixstores-graphql-schema';
import {clickOnProductOptionSf} from '@wix/bi-logger-ec-sf/v2';
import {makeAutoObservable} from 'mobx';
import {IProductExtendedOption} from '../../types/type';
import {ChoiceItem} from './config/types';

export default model.createController((controllerParams) => {
  const {$bindAll, flowAPI, $props, $widget, $w} = controllerParams;
  const mapChoice = (choice): ChoiceItem => ({
    _id: choice.id.toString(),
    selectionId: choice.id,
    value: choice.value,
    description: choice.description,
    isDisabled: choice.isDisabled,
  });
  const optionStates = [0, 1, 2, 3, 4, 5].map((i) =>
    makeAutoObservable({
      get isExist() {
        return i < $props.productOptions.length;
      },
      get optionData() {
        return optionStates[i].isExist && ($props.productOptions[i] as IProductExtendedOption);
      },
      get type() {
        return optionStates[i].optionData.optionType;
      },
      get optionId(): string {
        return optionStates[i].optionData?.id;
      },
      get title() {
        return optionStates[i].optionData?.title ?? '';
      },
      get visibleChoices() {
        return optionStates[i].optionData?.selections?.filter((choice) => choice.isVisible).map(mapChoice) || [];
      },
      get isError() {
        return !!optionStates[i].optionData?.validation?.isError;
      },
      get errorMessage() {
        return optionStates[i].optionData?.validation?.errorMessage ?? '';
      },
    }),
  );

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.Repeater]: {
          data: () =>
            $props.productOptions.map((option, index) => ({
              _id: index.toString(),
              ...option,
            })),
          item: (itemData, item, index) => {
            const $optionState = optionStates[index];
            const handleOptionChange = (selectionId) => {
              void flowAPI.bi.report(
                clickOnProductOptionSf({
                  isBlocks: true,
                }),
              );
              $widget.fireEvent(Events.Change, {selectionId, optionId: $optionState.optionId});
            };

            return {
              [ItemComponentIds.ColorOption]: {
                onChange: (event: $w.Event & {data: any}) => {
                  handleOptionChange(event.data);
                },
                selectionIds: () => $props.selectionIds,
                label: () => $optionState.title,
                shouldShowTitle: () => $props.settings?.shouldShowTitle,
                selections: () => ($optionState.type === OptionType.COLOR ? $optionState.visibleChoices : []),
                isError: () => $optionState.isError,
                errorMessage: () => $optionState.errorMessage,
              },
              [ItemComponentIds.DropdownOption]: {
                onChange: (event: $w.Event & {data: any}) => {
                  handleOptionChange(event.data);
                },
                shouldShowTitle: () => $props.settings?.shouldShowTitle,
                selectionIds: () => $props.selectionIds,
                label: () => $optionState.title,
                selections: () => ($optionState.type === OptionType.DROP_DOWN ? $optionState.visibleChoices : []),
                isError: () => $optionState.isError,
                errorMessage: () => $optionState.errorMessage,
              },
              [ItemComponentIds.MultiStateBox]: {
                currentState: () =>
                  $optionState.type === OptionType.DROP_DOWN ? OptionsStates.DropdownState : OptionsStates.ColorState,
              },
            };
          },
        },
      });
    },
    exports: {
      focus: (index: number) => {
        const $optionState = optionStates[index];
        $w(ComponentIds.Repeater).forItems([index.toString()], ($item) => {
          if ($optionState.isExist && $optionState.type === OptionType.DROP_DOWN) {
            $item(ItemComponentIds.DropdownOption).focus();
          } else {
            $item(ItemComponentIds.ColorOption).focus();
          }
        });
      },
    },
  };
});
