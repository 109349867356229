import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'errorMessageWidget',
  props: {
    text: {
      type: 'text',
      title: 'Error Text',
      defaultValue: undefined,
    },
  },
  methods: {
    getTextSelector: {},
  },
  events: {},
});
