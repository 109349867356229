import {RootStore} from '../../../stores/RootStore';
import {getPriceRange} from '../../product-service/pricing/getPriceRange';
import {getPriceBreakdown} from '../../product-service/pricing/getPriceBreakdown';
import {isDigitalProduct} from '../../product-service/isDigitalProduct';
import {getPricePerUnitData} from '../../product-service/pricing/getPricePerUnitData';
import {getFrequencyTitle} from '../../product-service/pricing/getSubscriptionFrequencyTranslationKey';
import {getCashierBannerProps} from '../../product-service/getCashierBannerProps';
import {makeAutoObservable} from 'mobx';
import {RootWidgetComponentIds} from '../../../config/constants';
import type {IPaymentMethodsBannerProps} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/wixcode/payments/getPaymentMethodsBannerProps';

export const bindPriceWidgetProps = (rootStore: RootStore) => {
  const {$bind} = rootStore.controllerParams;
  const {$state} = rootStore;

  const priceStore = makeAutoObservable({
    get pricePerUnit() {
      return getPricePerUnitData(rootStore.$state, rootStore.controllerParams.flowAPI.translations.t);
    },
    get priceRange() {
      return getPriceRange(rootStore);
    },
    get priceBreakdown() {
      return getPriceBreakdown(rootStore);
    },
    get frequencyLabel() {
      return getFrequencyTitle(rootStore);
    },
    get cashierBannerProps(): IPaymentMethodsBannerProps {
      return getCashierBannerProps(rootStore);
    },
  });
  return $bind(RootWidgetComponentIds.Price, {
    formattedActualPrice: () =>
      priceStore.priceRange.showPriceRange ? priceStore.priceRange.fromPriceFormatted : $state.formattedActualPrice,
    formattedOriginalPrice: () => $state.formattedOriginalPrice,
    formattedPricePerUnit: () => $state.formattedPricePerUnit,
    formattedUnitAndQuantity: () => priceStore.pricePerUnit.formattedUnitAndQuantity.abbr,
    srFormattedUnitAndQuantity: () => priceStore.pricePerUnit.formattedUnitAndQuantity.full,
    frequencyLabel: () => priceStore.frequencyLabel,
    showDiscount: () => $state.hasDiscount && !$state.selectedSubscriptionPlan,
    showPricePerUnit: () => priceStore.pricePerUnit.showPricePerUnit,
    showPriceRange: () => priceStore.priceRange.showPriceRange,
    showTaxDisclaimer: () => priceStore.priceBreakdown.showTaxDisclaimer,
    taxDisclaimerText: () => priceStore.priceBreakdown.taxDisclaimerText,
    showShippingDisclaimer: () =>
      priceStore.priceBreakdown.shippingDisclaimer?.show && !isDigitalProduct(rootStore.$state),
    showShippingDisclaimerModal: () => priceStore.priceBreakdown.shippingDisclaimer?.additionalInfo.show,
    shippingDisclaimerText: () => priceStore.priceBreakdown.shippingDisclaimer?.displayText,
    cashierBannerProps: () => priceStore.cashierBannerProps,
    deleted: () => false,
    showDiscountTitle: () => $state.isAutomatedDiscount,
    discountTitle: () => $state.discountTitle,
  });
};
