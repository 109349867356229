import {RootStore} from '../../../stores/RootStore';
import {SubscriptionPlansView} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSubscriptionService/type';
import {SubscriptionService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSubscriptionService/SubscriptionService';
import {getSubscriptionDetailsTranslation} from './translationUtils';

const createSubscriptionService = (rootStore: RootStore): SubscriptionService => {
  const oneTimePurchasePlan = {
    label: rootStore.t.PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL(),
    place: 0,
    price: rootStore.$state.formattedOneTimePurchasePrice,
  };
  return new SubscriptionService(rootStore.$state.product.subscriptionPlans, oneTimePurchasePlan);
};

export const getSubscriptionOptions = (rootStore: RootStore): SubscriptionPlansView[] => {
  const subscriptionService = createSubscriptionService(rootStore);
  const state = rootStore.$state;
  return subscriptionService
    .getSubscriptionPlansView(state.product, state.selectedVariant?.id, state.subscriptionInput)
    .map((plan) => {
      const fullSubscriptionPlan = subscriptionService.getSubscriptionPlans().find((p) => p.id === plan.id);
      return {
        ...plan,
        frequency: getSubscriptionDetailsTranslation({
          rootStore,
          plan: fullSubscriptionPlan,
        }),
      };
    });
};
